import { Dispatch } from "redux";
import { dashboardActionTypes } from "./constants";
import {
  IGetMainResponseModel,
  IGetStatusBarModel,
  ILastPlayer,
  IRequestDeleteGameModel,
  IRequestCloseRoomModel,
  IRequestGameReadyModel,
  IRequestPlayGameModel,
  IRequestRoundValueModel,
  IRequestTimerExpiredModel,
  ISocketGameModel,
  IAgreementRules,
  IGame, 
  IEventGALobby,
} from "@common/types/dashboard.types";
import {
  getMainApi,
  getStatisticRoundApi,
  getAllDuelsApi,
  getGamesApi,
  getStatusBarApi,
  postGameInfo,
  getTOValueApi,
  postRoundValue,
  postRoundArrValue,
  postGameReady,
  postRoundTimerExpired,
  postTimerExpired,
  postRoundStarted,
  deleteCreatedGame,
  closeRoom,
  agreementRules, 
  postGAStatistics,
} from "@api/dashboard";
import { showErrorToast } from "@constants/request.constants";
import http from "@api/http";

import omit from "lodash/omit";
import get from "lodash/get";
import { setValueToLocalStorage } from "@services/tokenStorageService";
import { StatusBarCacheManager } from "@services/statusBarCache";

export const dashboardActions = {
  // GET_MAIN_INFO
  getMainInfoAction: () => ({
    type: dashboardActionTypes.GET_MAIN_INFO,
  }),
  getMainInfoSuccessAction: (payload: IGetMainResponseModel) => ({
    type: dashboardActionTypes.GET_MAIN_INFO_SUCCESS,
    payload,
  }),
  getMainInfoFailAction: (payload: any) => ({
    type: dashboardActionTypes.GET_MAIN_INFO_FAIL,
    payload,
  }),

  // GET_STATISTIC_ROUND
  getStatisticRoundAction: () => ({
    type: dashboardActionTypes.GET_STATISTIC_ROUND,
  }),
  getStatisticRoundSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.GET_STATISTIC_ROUND_SUCCESS,
    payload,
  }),
  getStatisticRoundFailAction: (payload: any) => ({
    type: dashboardActionTypes.GET_STATISTIC_ROUND_FAIL,
    payload,
  }),

  // GET_ALL_DUELS
  getAllDuelsAction: () => ({
    type: dashboardActionTypes.GET_ALL_DUELS,
  }),
  getAllDuelsSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.GET_ALL_DUELS_SUCCESS,
    payload,
  }),
  getAllDuelsFailAction: (payload: any) => ({
    type: dashboardActionTypes.GET_ALL_DUELS_FAIL,
    payload,
  }),

  // GET_GAMES
  getGamesAction: () => ({
    type: dashboardActionTypes.GET_GAMES,
  }),
  getGamesSuccessAction: (payload: IGame[]) => ({
    type: dashboardActionTypes.GET_GAMES_SUCCESS,
    payload,
  }),
  getGamesFailAction: (payload: any) => ({
    type: dashboardActionTypes.GET_GAMES_FAIL,
    payload,
  }),

  // GET_STATUS_BAR
  getStatusBarAction: () => ({
    type: dashboardActionTypes.GET_STATUS_BAR,
  }),
  getStatusBarSuccessAction: (payload: IGetStatusBarModel) => ({
    type: dashboardActionTypes.GET_STATUS_BAR_SUCCESS,
    payload,
  }),
  getStatusBarFailAction: (payload: any) => ({
    type: dashboardActionTypes.GET_STATUS_BAR_FAIL,
    payload,
  }),

  // LAST_PLAYER_LIST
  setLastPlayerListAction: (payload: any) => ({
    type: dashboardActionTypes.LAST_PLAYER_LIST,
    payload,
  }),

  // LAST_PLAYERS
  setLastPlayersAction: (payload: any) => ({
    type: dashboardActionTypes.LAST_PLAYERS,
    payload,
  }),

  // ADD_LAST_PLAYERS
  setAddLastPlayersAction: (payload: boolean) => ({
    type: dashboardActionTypes.ADD_LAST_PLAYERS,
    payload,
  }),

  // POST GAME INFO
  postGameInfoAction: () => ({
    type: dashboardActionTypes.POST_PLAY_GAME,
  }),
  postGameInfoSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.POST_PLAY_GAME_SUCCESS,
    payload,
  }),
  postGameInfoFailAction: (payload: any) => ({
    type: dashboardActionTypes.POST_PLAY_GAME_FAIL,
    payload,
  }),

  // GET TO VALUE
  getTOValueAction: () => ({
    type: dashboardActionTypes.GET_TO_VALUE,
  }),
  getTOValueSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.GET_TO_VALUE_SUCCESS,
    payload,
  }),
  getTOValueFailAction: (payload: any) => ({
    type: dashboardActionTypes.GET_TO_VALUE_FAIL,
    payload,
  }),

  // POST_GAME_CHOICE_VALUE
  postChoiceValueAction: () => ({
    type: dashboardActionTypes.POST_GAME_CHOICE_VALUE,
  }),
  postChoiceValueSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.POST_GAME_CHOICE_VALUE_SUCCESS,
    payload,
  }),
  postChoiceValueFailAction: (payload: any) => ({
    type: dashboardActionTypes.POST_GAME_CHOICE_VALUE_FAIL,
    payload,
  }),

  // POST_GAME_READY
  postGameReadyAction: () => ({
    type: dashboardActionTypes.POST_GAME_READY,
  }),
  postGameReadySuccessAction: (payload: any) => ({
    type: dashboardActionTypes.POST_GAME_READY_SUCCESS,
    payload,
  }),
  postGameReadyFailAction: (payload: any) => ({
    type: dashboardActionTypes.POST_GAME_READY_FAIL,
    payload,
  }),

  // POST_TIMER_EXPIRED
  postTimerExpiredAction: () => ({
    type: dashboardActionTypes.POST_TIMER_EXPIRED,
  }),
  postTimerExpiredSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.POST_TIMER_EXPIRED_SUCCESS,
    payload,
  }),
  postTimerExpiredFailAction: (payload: any) => ({
    type: dashboardActionTypes.POST_TIMER_EXPIRED_FAIL,
    payload,
  }),

  // POST_ROUND_STARTED
  postRoundStartedAction: () => ({
    type: dashboardActionTypes.POST_ROUND_STARTED,
  }),
  postRoundStartedSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.POST_ROUND_STARTED_SUCCESS,
    payload,
  }),
  postRoundStartedFailAction: (payload: any) => ({
    type: dashboardActionTypes.POST_ROUND_STARTED_FAIL,
    payload,
  }),

  // DELETE_CREATED_GAME
  deleteCreatedGameAction: () => ({
    type: dashboardActionTypes.DELETE_CREATED_GAME,
  }),
  deleteCreatedGameSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.DELETE_CREATED_GAME_SUCCESS,
    payload,
  }),
  deleteCreatedGameFailAction: (payload: any) => ({
    type: dashboardActionTypes.DELETE_CREATED_GAME_FAIL,
    payload,
  }),

  // CLOSE_ROOM
  closeRoomAction: () => ({
    type: dashboardActionTypes.CLOSE_ROOM,
  }),
  closeRoomSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.CLOSE_ROOM_SUCCESS,
    payload,
  }),
  closeRoomFailAction: (payload: any) => ({
    type: dashboardActionTypes.CLOSE_ROOM_FAIL,
    payload,
  }),

  // SOCKET_MESSAGE_GAME
  socketMessageGame: (payload: any) => ({
    type: dashboardActionTypes.SOCKET_MESSAGE_GAME,
    payload,
  }),

  // AGREEMENT_RULES
  agreementRules: () => ({
    type: dashboardActionTypes.AGREEMENT_RULES,
  }),
  agreementRulesSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.AGREEMENT_RULES_SUCCESS,
    payload,
  }),
  agreementRulesFailAction: (payload: any) => ({
    type: dashboardActionTypes.AGREEMENT_RULES_FAIL,
    payload,
  }),

  // SELECT_GAME
  selectGameSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.SELECT_GAME,
    payload,
  }),

  // SELECT_RATE
  selectRateSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.SELECT_RATE,
    payload,
  }),

  // SELECT_COIN
  selectCoinSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.SELECT_COIN,
    payload,
  }),

  // REWARDS_FOR_SIGNUP
  setRewardsForSignUp: (payload: boolean) => ({
    type: dashboardActionTypes.REWARDS_FOR_SIGNUP,
    payload,
  }),

  // BEFORE_GAME
  setBeforeGame: (payload: boolean) => ({
    type: dashboardActionTypes.BEFORE_GAME,
    payload,
  }),

  // IS_CLARNIUM
  setIsClarnium: (payload: boolean) => ({
    type: dashboardActionTypes.IS_CLARNIUM,
    payload,
  }),

  // PROFILE_BACK
  setProfileBack: (payload: string) => ({
    type: dashboardActionTypes.PROFILE_BACK,
    payload,
  }),

  // CLEAR_ERROR
  clearErrorAction: (payload: any) => ({
    type: dashboardActionTypes.CLEAR_ERROR,
    payload,
  }),

  // EVENTS GA
  // POST GAME INFO
  postGaStatictics: () => ({
    type: dashboardActionTypes.POST_GA_STATISTICS,
  }),
  postGaStaticticsSuccessAction: (payload: any) => ({
    type: dashboardActionTypes.POST_GA_STATISTICS_SUCCESS,
    payload,
  }),
  postGaStaticticsFailAction: (payload: any) => ({
    type: dashboardActionTypes.POST_GA_STATISTICS_FAIL,
    payload,
  }),
};

export const getMainInfo = (params: any) => async (dispatch: Dispatch) => {
  const send = http(dispatch);

  dispatch(dashboardActions.getMainInfoAction());
  try {
    const response = await send(getMainApi());
    const data = response.data;

    if (data.currencies.find((item: any) => item.currencyCode === "XCLAR")) {
      dispatch(dashboardActions.setIsClarnium(true));
    }
    if (data.wtJackpots) {
      setValueToLocalStorage("wtJackpots", JSON.stringify(data.wtJackpots));
    }
    dispatch(dashboardActions.getMainInfoSuccessAction(data));
    return data

  } catch (error) {
    const errorResponse = get(error, "response.data.message", "");

    showErrorToast(errorResponse, params.intl);
    dispatch(dashboardActions.getMainInfoFailAction(errorResponse));
  }
};

export const getStatisticRound = (params: any) => async (dispatch: Dispatch) => {
  const send = http(dispatch);

  dispatch(dashboardActions.getStatisticRoundAction());
  try {
    const response = await send(getStatisticRoundApi());
    const data = response.data;

    dispatch(dashboardActions.getStatisticRoundSuccessAction(data));
    return data

  } catch (error) {
    const errorResponse = get(error, "response.data.message", "");

    showErrorToast(errorResponse, params.intl);
    dispatch(dashboardActions.getStatisticRoundFailAction(errorResponse));
  }
};

export const getAllDuels = (params: any) => async (dispatch: Dispatch) => {
  const send = http(dispatch);
  const query: any = {};
  if (params.page) { 
    query["page"] = params.page; 
    query["limit"] = 10; 
  };
  if (params.gameId) { query["gameId"] = params.gameId };

  dispatch(dashboardActions.getAllDuelsAction());
  try {
    const response = await send(getAllDuelsApi(query));
    const data = response.data;

    if (params.page) {
      dispatch(dashboardActions.getAllDuelsSuccessAction(data));
    }
    return data

  } catch (error) {
    const errorResponse = get(error, "response.data.message", "");

    showErrorToast(errorResponse, params.intl);
    dispatch(dashboardActions.getAllDuelsFailAction(errorResponse));
  }
};

export const getGames = (params: any) => async (dispatch: Dispatch) => {
  const send = http(dispatch);

  dispatch(dashboardActions.getGamesAction());
  try {
    const response = await send(getGamesApi());
    const data = response.data;

    dispatch(dashboardActions.getGamesSuccessAction(data));
    return data

  } catch (error) {
    const errorResponse = get(error, "response.data.message", "");

    showErrorToast(errorResponse, params.intl);
    dispatch(dashboardActions.getGamesFailAction(errorResponse));
  }
};

export const getStatusBar = (params: any, forceRefresh: boolean = false) => async (dispatch: Dispatch) => {
  const send = http(dispatch);

  // Проверяем кеш
  if (!forceRefresh && StatusBarCacheManager.isValid()) {
    const cachedData = StatusBarCacheManager.get();
    if (cachedData) {
      dispatch(dashboardActions.getStatusBarSuccessAction(cachedData));
      return cachedData;
    }
  }

  dispatch(dashboardActions.getStatusBarAction());
  try {
    const response = await send(getStatusBarApi());
    const data = response.data.data;

    if (data.balance.find((item: any) => item.currency === "XCLAR")) {
      dispatch(dashboardActions.setIsClarnium(true));
    }

    // Сохраняем в кеш
    StatusBarCacheManager.set(data);

    dispatch(dashboardActions.getStatusBarSuccessAction(data));
    return data

  } catch (error) {
    const errorResponse = get(error, "response.data.message", "");

    showErrorToast(errorResponse, params.intl);
    dispatch(dashboardActions.getStatusBarFailAction(errorResponse));
  }
};

// Экспортируем метод очистки кеша для использования в других местах
export const clearStatusBarCache = () => {
  StatusBarCacheManager.clear();
};

export const setLastPlayerList = (params: ILastPlayer[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(dashboardActions.setLastPlayerListAction(params))
  } catch (error) {
    console.log("error", error)
  }
};

export const setLastPlayers = (params: ILastPlayer[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(dashboardActions.setLastPlayersAction(params))
  } catch (error) {
    console.log("error", error)
  }
};

export const setAddLastPlayers = (params: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch(dashboardActions.setAddLastPlayersAction(params))
  } catch (error) {
    console.log("error", error)
  }
};

export const postCreateGame =
  (params: IRequestPlayGameModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.postGameInfoAction());
    try {
      // @ts-ignore
      const response = await send(postGameInfo(omit(params, ["intl"])));
      const data = response.data;

      dispatch(dashboardActions.postGameInfoSuccessAction(response.data));
      return data;
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, "response.data.error", "");

      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.postGameInfoFailAction(errorResponse));
      throw new Error(errorResponse);
    }
  };

  export const getTOValue = (params: any) => async (dispatch: Dispatch) => {
    const send = http(dispatch);
  
    dispatch(dashboardActions.getTOValueAction());
    try {
      const response = await send(getTOValueApi(params.key));
      const data = response.data;
  
      dispatch(dashboardActions.getTOValueSuccessAction(data));
      return data
  
    } catch (error) {
      const errorResponse = get(error, "response.data.message", "");
  
      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.getStatusBarFailAction(errorResponse));
    }
  };

export const deleteCreateGame = (params: IRequestDeleteGameModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.deleteCreatedGameAction())
    try {
      const response = await send(deleteCreatedGame(omit(params, ['intl'])));
      const data = response.data;

      dispatch(dashboardActions.deleteCreatedGameSuccessAction(data));
      return data
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.deleteCreatedGameFailAction(errorResponse));
      throw new Error(errorResponse);
    }
  };

export const closeRoomAction = (params: IRequestCloseRoomModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.closeRoomAction())
    try {
      const response = await send(closeRoom(omit(params, ['intl'])));

      dispatch(dashboardActions.closeRoomSuccessAction(response.data));
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.closeRoomFailAction(errorResponse));
    }
  };

export const postGameChoiceValueAction =
  (params: IRequestRoundValueModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.postChoiceValueAction());
    try {
      const response = await send(postRoundValue(omit(params, ["intl"])));

      dispatch(dashboardActions.postChoiceValueSuccessAction(response.data));
    } catch (error) {
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.postChoiceValueFailAction(errorResponse));
    }
  };

export const postGameChoiceArrValueAction =
  (params: IRequestRoundValueModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.postChoiceValueAction());
    try {
      const response = await send(postRoundArrValue(omit(params, ["intl"])));

      dispatch(dashboardActions.postChoiceValueSuccessAction(response.data));
    } catch (error) {
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.postChoiceValueFailAction(errorResponse));
    }
  };

export const postGameReadyAction =
  (params: IRequestGameReadyModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.postGameReadyAction());
    try {
      const response = await send(postGameReady(omit(params, ["intl"])));

      dispatch(dashboardActions.postGameReadySuccessAction(response.data));
    } catch (error) {
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.postGameReadyFailAction(errorResponse));
    }
  };

export const postRoundTimerExpiredAction =
  (params: IRequestTimerExpiredModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.postTimerExpiredAction());
    try {
      const response = await send(postRoundTimerExpired(omit(params, ["intl"])));

      dispatch(dashboardActions.postTimerExpiredSuccessAction(response.data));
    } catch (error) {
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl)
      dispatch(dashboardActions.postTimerExpiredFailAction(errorResponse));
    }
  };

export const postTimerExpiredAction =
  (params: IRequestTimerExpiredModel) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.postTimerExpiredAction());
    try {
      const response = await send(postTimerExpired(omit(params, ["intl"])));

      dispatch(dashboardActions.postTimerExpiredSuccessAction(response.data));
    } catch (error) {
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl)
      dispatch(dashboardActions.postTimerExpiredFailAction(errorResponse));
    }
  };

  export const postRoundStartedAction =
    (params: IRequestGameReadyModel) => async (dispatch: Dispatch) => {
      const send = http(dispatch);
  
      dispatch(dashboardActions.postRoundStartedAction());
      try {
        const response = await send(postRoundStarted(omit(params, ["intl"])));
  
        dispatch(dashboardActions.postRoundStartedSuccessAction(response.data));
      } catch (error) {
        const errorResponse = get(error, "response.data.message", "");
  
        showErrorToast(errorResponse, params.intl)
        dispatch(dashboardActions.postRoundStartedFailAction(errorResponse));
      }
    };

export const socketMessageAction =
  (params: ISocketGameModel) => async (dispatch: Dispatch) => {
  try {
    // @ts-ignore
    dispatch(dashboardActions.socketMessageGame(params));
    dispatch(dashboardActions.getStatisticRoundSuccessAction(params.statisticRound));
  } catch (error) {
    console.log("error", error)
  }
};

export const agreementRulesAction =
  (params: IAgreementRules) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.agreementRules());
    try {
      const response = await send(agreementRules(omit(params, ["intl"])));

      dispatch(dashboardActions.agreementRulesSuccessAction(response.data));
    } catch (error) {
      const errorResponse = get(error, "response.data.message", "");

      showErrorToast(errorResponse, params.intl);
      dispatch(dashboardActions.agreementRulesFailAction(errorResponse));
    }
  };

export const selectGameAction = (params: IGame) => async (dispatch: Dispatch) => {
  try {
    // @ts-ignore
    dispatch(dashboardActions.selectGameSuccessAction(params));
  } catch (error) {
    console.log("error", error);
  }
};

export const selectRateAction = (params: number) => async (dispatch: Dispatch) => {
  try {
    // @ts-ignore
    dispatch(dashboardActions.selectRateSuccessAction(params));
  } catch (error) {
    console.log("error", error);
  }
};

export const selectCoinAction = (params: string) => async (dispatch: Dispatch) => {
  try {
    // @ts-ignore
    dispatch(dashboardActions.selectCoinSuccessAction(params));
  } catch (error) {
    console.log("error", error);
  }
};

export const setRewardsForSignUpAction = (params: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(dashboardActions.setRewardsForSignUp(params));
  } catch (error) {
    console.log("error", error);
  }
};

export const setBeforeGameAction = (params: boolean) => (dispatch: Dispatch) => {
  try {
    dispatch(dashboardActions.setBeforeGame(params));
  } catch (error) {
    console.log("error", error);
  }
};

export const setProfileBackAction = (params: string) => (dispatch: Dispatch) => {
  try {
    dispatch(dashboardActions.setProfileBack(params));
  } catch (error) {
    console.log("error", error);
  }
};

export const setClearErrorAction = (params: any) => (dispatch: Dispatch) => {
  try {
    dispatch(dashboardActions.clearErrorAction(params));
  } catch (error) {
    console.log("error", error);
  }
};

export const postGaStatistics =
  (params: IEventGALobby, game: number) => async (dispatch: Dispatch) => {
    const send = http(dispatch);

    dispatch(dashboardActions.postGaStatictics());
    try {
      const response = await send(postGAStatistics(params, game));
      const data = response.data;

      dispatch(dashboardActions.postGaStaticticsSuccessAction(response.data));
      return data;
    } catch (error) {
      // @ts-ignore
      const errorResponse = get(error, "response.data.error", "");

      dispatch(dashboardActions.postGaStaticticsFailAction(errorResponse));
    }
  };
