import {
  IRequestDeleteGameModel,
  IRequestCloseRoomModel,
  IRequestGameReadyModel,
  IRequestGameResultModel,
  IRequestPlayGameModel,
  IRequestRoundCloseModel,
  IRequestRoundResultModel,
  IRequestRoundValueModel,
  IRequestTimerExpiredModel,
  IAgreementRules, IEventGALobby,
} from "@common/types/dashboard.types";

export const getMainApi = () => ({
  url: "/main/get-main",
  method: "GET",
  clientCache: true,
});

export const getStatisticRoundApi = () => ({
  url: "/main/statistic-round",
  method: "GET",
});

export const getAllDuelsApi = (params: any) => ({
  url: "/main/all-duels",
  method: "GET",
  params,
  clientCache: true,
});

export const getGamesApi = () => ({
  url: "/game",
  method: "GET",
  clientCache: true,
});

export const getStatusBarApi = () => ({
  url: "/main/status-bar",
  method: "GET",
});

export const postGameInfo = (params: IRequestPlayGameModel) => ({
  url: "/play/game",
  method: "POST",
  data: params,
});

export const getTOValueApi = (key: string) => ({
  url: "/play/game/get-xxi-value",
  method: "GET",
  params: {
    key
  },
});


export const postGameReady = (params: IRequestGameReadyModel) => ({
  url: `play/round/ready/${params.statisticRoundId}`,
  method: "GET",
});


export const postRoundValue = (params: IRequestRoundValueModel) => ({
  url: `play/round/value/${params.statisticRoundId}`,
  method: "POST",
  data: {
    value: params.value,
  },
});

export const postRoundArrValue = (params: IRequestRoundValueModel) => ({
  url: `play`,
  method: "POST",
  data: {
    statisticRoundId: params.statisticRoundId,
    value: params.value,
  },
});

export const postRoundTimerExpired = (params: IRequestTimerExpiredModel) => ({
  url: `play/round/timer/expired/${params.timerExpired}`,
  method: "GET",
});

export const postTimerExpired = (params: IRequestTimerExpiredModel) => ({
  url: `play/timer-expired/${params.timerExpired}`,
  method: "POST",
  data: {},
});

export const postRoundStarted = (params: IRequestGameReadyModel) => ({
  url: `play/round-started/${params.statisticRoundId}`,
  method: "POST",
  data: {},
});

export const deleteCreatedGame = (params: IRequestDeleteGameModel) => ({
  url: `user/waiting/list/${params.gameId}`,
  method: "DELETE",
});

export const closeRoom = (params: IRequestCloseRoomModel) => ({
  url: `room/close-full/${params.roomId}`,
  method: "POST",
  data: {},
});

export const postRoundClose = (params: IRequestRoundCloseModel) => ({
  url: `play/round/close/${params.roundClose}`,
  method: "GET",
});


export const postRoundResult = (params: IRequestRoundResultModel) => ({
  url: `play/round/result/${params.roundResult}`,
  method: "GET",
});


export const postGameResult = (params: IRequestGameResultModel) => ({
  url: `play/game/result/${params.gameRessult}`,
  method: "GET",
});

export const agreementRules = (params: IAgreementRules) => ({
  url: `game/agree/agreement/${params.gameId}`,
  method: "GET",
});

export const postGAStatistics = (params: IEventGALobby, game: number = 1) => ({
  url: `main/ga-statistics/${game}`,
  method: "POST",
  data: params,
});
