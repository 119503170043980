import axios from 'axios';
import { AppConfig } from "@services/config";

export enum GameErrorType {
  SOCKET_ERROR = 'Socket Connection Error',
  GAME_CREATION_ERROR = 'Game Creation Error',
  OPPONENT_SEARCH_TIMEOUT = 'Opponent Search Timeout',
  ROOM_CLOSE_ERROR = 'Room Close Error',
  EMAIL_AUTH_ERROR = 'Email Authentication Error',
  TELEGRAM_AUTH_ERROR = 'Telegram Authentication Error'
}

interface ErrorNotificationPayload {
  errorType: GameErrorType;
  userId?: string | number;
  gameId?: string | number;
  gameType?: string;
  rate?: number;
  currency?: string;
  additionalInfo?: string;
}

export class DiscordNotificationService {
  private webhookUrl: string;


  constructor(webhookUrl: string) {
    this.webhookUrl = webhookUrl;
  }

  async sendErrorNotification({
    errorType,
    userId,
    gameId,
    gameType,
    rate,
    currency,
    additionalInfo
  }: ErrorNotificationPayload): Promise<boolean> {
    const embed = {
      title: `🚨 Game Error: ${errorType}`,
      color: 0xFF0000,
      fields: [
        {
          name: 'Timestamp',
          value: new Date().toISOString(),
          inline: true
        }
      ],
      footer: {
        text: 'Game Platform Error Monitoring'
      }
    };

    // Add optional fields if they exist
    if (userId) {
      embed.fields.push({
        name: 'User ID',
        value: userId.toString(),
        inline: true
      });
    }

    if (gameId) {
      embed.fields.push({
        name: 'Game ID',
        value: gameId.toString(),
        inline: true
      });
    }

    if (gameType) {
      embed.fields.push({
        name: 'Game Type',
        value: gameType,
        inline: true
      });
    }

    if (rate && currency) {
      embed.fields.push({
        name: 'Bet Amount',
        value: `${rate} ${currency}`,
        inline: true
      });
    }

    if (additionalInfo) {
      embed.fields.push({
        name: 'Additional Info',
        value: additionalInfo,
        inline: false
      });
    }

    try {
      const response = await axios.post(this.webhookUrl, {
        embeds: [embed]
      });
      
      return response.status === 204;
    } catch (error) {
      console.error('Failed to send Discord notification:', error);
      return false;
    }
  }
}

export const discordNotification = new DiscordNotificationService(
  'https://discord.com/api/webhooks/1306538107553976330/Geed2blwWSPT1B4e6EVCqjIop1CPUKG7hG8GqgeNJlo9a-88lh9nADXwGOkuiO3l8FTB'
);