import React from 'react';
import {FormattedMessage} from "react-intl";

import {
  MaintenanceContainer,
  MaintenanceIcon,
  MaintenanceText,
} from "./My.styled";

import CoinIcon from "@icons/other/coin.png";

const MaintenanceScreen = () => {

  return (
    <MaintenanceContainer>
      <MaintenanceIcon>
        <img src={CoinIcon} alt="" />
      </MaintenanceIcon>
      <MaintenanceText>
        <FormattedMessage id="loginPage.maintenance"/>
      </MaintenanceText>
      <MaintenanceText>
        <FormattedMessage id="loginPage.wait"/>
      </MaintenanceText>
    </MaintenanceContainer>
  );
}

export default MaintenanceScreen;
